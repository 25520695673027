import React from "react";
import { Spinner, Box } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
      position="absolute"
      zIndex="1"
    >
      <Spinner
        thickness="3px"
        speed="0.65s"
        emptyColor="transparent"
        color="#745AC3"
        size="md"
      />
    </Box>
  );
};

export default Loader;
