import { Box, Button, FormControl, Image, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import Web3 from "web3";
import "../../styles/modals.css";
import { landAbi } from "../abi/land";
import { abi } from "../abi/marketplace";
import { wearableAbi } from "../abi/wearable";
import {
  landAddress,
  marketplaceAddress,
  wearableAddress,
  priorityFee
} from "../../utils/utils";

const SellDetails = ({ data, owned }) => {
  console.log(data);
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState("");
  const [error2, setError2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [message, setMessage] = useState("");
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [landContract, setLandContract] = useState(null);
  const [wearableContract, setWearableContract] = useState(null);
  const { ethereum } = window;
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (ethereum) {
      const web3Instance = new Web3(ethereum);
      setWeb3(web3Instance);
      const contractInstance = new web3Instance.eth.Contract(
        abi,
        marketplaceAddress
      );
      const land = new web3Instance.eth.Contract(landAbi, landAddress);
      const wearables = new web3Instance.eth.Contract(
        wearableAbi,
        wearableAddress
      );
      setContract(contractInstance);
      setLandContract(land);
      setWearableContract(wearables);
    } else {
      console.log("Please install MetaMask!");
    }
  }, [ethereum]);

  console.log(data);

  const handleClick = async (data) => {
    console.log(data);

    if (show) {
      if (!price) {
        setError2(true);
        return;
      }
      setLoading(true);
      console.log(price);
      try {
        if (
          !web3 ||
          !web3.currentProvider ||
          !web3.currentProvider.selectedAddress
        ) {
          alert("Please connect to MetaMask.");
          setLoading(false);
          return;
        }

        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const value = web3.utils.toWei(price, "ether");
        console.log(value);
        const account = accounts[0];
        // approving
        let approval;
        if (data.category === "Land") {
          console.log(marketplaceAddress, data.id);
          approval = await landContract.methods
            .approve(marketplaceAddress, data.id)
            .send({ from: account,maxPriorityFeePerGas: priorityFee });
        } else {
          approval = await wearableContract.methods
            .approve(marketplaceAddress, data.id)
            .send({ from: account,maxPriorityFeePerGas: priorityFee });
        }
        console.log(approval);
        let receipt;
        if (approval) {
          console.log(data.nftAddress, data.id, value);

          receipt = await contract.methods
            .listItem(data.nftAddress, data.id, value)
            .send({ from: account,maxPriorityFeePerGas: priorityFee });
        }
        console.log(receipt);
        console.log("Transaction receipt:", receipt);
        setSuccess(true);
        setMessage("Transaction successful!");
      } catch (error) {
        console.error("Error buying item:", error);
        setFailure(true);
        setMessage(error.message);
      }
      setLoading(false);
    } else {
      setShow(!show);
    }
  };

  return (
    <>
      {success ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="100%"
          flexDir="column"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 246 246"
              fill="none"
            >
              <path
                d="M122.558 0C55.0288 0 0 55.0288 0 122.558C0 190.088 55.0288 245.117 122.558 245.117C190.088 245.117 245.117 190.088 245.117 122.558C245.117 55.0288 190.088 0 122.558 0ZM181.141 94.37L111.651 163.861C109.935 165.576 107.606 166.557 105.155 166.557C102.704 166.557 100.375 165.576 98.6596 163.861L63.9755 129.177C60.4213 125.622 60.4213 119.74 63.9755 116.185C67.5297 112.631 73.4125 112.631 76.9667 116.185L105.155 144.374L168.15 81.3788C171.704 77.8246 177.587 77.8246 181.141 81.3788C184.696 84.933 184.696 90.6933 181.141 94.37Z"
                fill="#5AAB53"
              />
            </svg>
          </Box>

          <Box textAlign="center">
            <Text
              color="#3D3D3D"
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize="1.6rem"
              lineHeight="108%"
              my="20px"
            >
              Listing successful!
            </Text>
            <Text
              color="#3D3D3D"
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize="1.2rem"
              lineHeight="108%"
              my="20px"
            >
              Please refresh ! It may take a while to update
            </Text>

            <Text
              color="#3B3B3B"
              fontFamily="Montserrat"
              fontWeight="400"
              fontSize="0.7rem"
              lineHeight="120%"
            >
              {message}
            </Text>
          </Box>
        </Box>
      ) : failure ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="100%"
          flexDir="column"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Image src="/images/cross.png" h="50px" w="50px" />
          </Box>

          <Box textAlign="center">
            <Text
              color="#3D3D3D"
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize="1.6rem"
              lineHeight="108%"
              my="20px"
            >
              Listing failed!
            </Text>
            <Text
              color="#3B3B3B"
              fontFamily="Montserrat"
              fontWeight="400"
              fontSize="0.7rem"
              lineHeight="120%"
            >
              {message}
            </Text>
          </Box>
        </Box>
      ) : (
        <Box>
          <Text
            fontFamily="Bebas Neue"
            fontWeight="400"
            color="#3D3D3D"
            fontSize="2.8rem"
            className="t-name"
          >
            {data.name}
          </Text>
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            mb="10px"
            h="auto"
            // className="sm-txt-cnt"
          >
            <Text
              fontFamily="Bebas Neue"
              fontSize="1.5rem"
              fontWeight="400"
              lineHeight="normal"
              color="#3B3B3B"
              zIndex="10"
              className="sm-title-m"
            >
              Owner ID :
            </Text>
            <Text
              fontFamily="Montserrat"
              fontSize="1rem"
              fontWeight="500"
              lineHeight="normal"
              color="#3B3B3B"
              className="sm-p-m"
              h="auto"
              w="70%"
            >
              {address}
            </Text>
          </Box>
          <Box
            display="flex"
            // flexWrap="wrap"
            alignItems="center"
            gap="10px"
            mb="10px"
            h="auto"
            w="45dvw"
            className="sm-txt-cnt"
          >
            <Text
              fontFamily="Bebas Neue"
              fontSize="1.5rem"
              fontWeight="400"
              lineHeight="normal"
              color="#3B3B3B"
              zIndex="10"
              className="sm-title-m"
            >
              Token ID :
            </Text>
            <Text
              fontFamily="Montserrat"
              fontSize="1rem"
              fontWeight="500"
              lineHeight="normal"
              color="#3B3B3B"
              className="sm-p-m"
              h="auto"
              w="80%"
            >
              {data.id}
            </Text>
          </Box>

          {show ? (
            <Box>
              <Text
                fontFamily="Bebas Neue"
                fontSize="1.5rem"
                fontWeight="400"
                lineHeight="normal"
                color="#745AC3"
                zIndex="10"
                mb="20px"
              >
                Sell Now
              </Text>

              <FormControl my="20px" isRequired>
                <Input
                  placeholder="Enter price in ETH"
                  className="form-inputs"
                  value={price}
                  type="number"
                  onChange={(e) => {
                    setPrice(e.target.value);
                    setError2(false);
                  }}
                  border={error2 ? "2px solid red" : "2px solid #745AC3"}
                />
              </FormControl>
            </Box>
          ) : (
            <Box>
              <Text
                fontFamily="Bebas Neue"
                fontSize="1.5rem"
                fontWeight="400"
                lineHeight="normal"
                color="#3B3B3B"
                zIndex="10"
                mb="5px"
              >
                Description
              </Text>
              <Text
                fontFamily="Montserrat"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="normal"
                color="#3D3D3D"
                mb="10px"
                w="95%"
              >
                {data.metadata.description}
              </Text>
              <Text
                fontFamily="Bebas Neue"
                fontSize="1.5rem"
                fontWeight="400"
                lineHeight="normal"
                color="#745AC3"
                zIndex="10"
                mb="30px"
              >
                Put On Sale
              </Text>
            </Box>
          )}
          {isConnected ? (
            <>
              {
                <Button
                  fontFamily="Bebas Neue"
                  fontWeight="400"
                  fontSize="2rem"
                  borderRadius=".4rem"
                  background="linear-gradient(90deg,#745AC3 66.98%,rgba(248,138,176,.8) 100%)"
                  color="white"
                  padding="23px 25px"
                  w="100%"
                  className="auth-btn"
                  onClick={() => handleClick(data)}
                  zIndex="10"
                  isLoading={loading}
                  disabled
                >
                  Sell Now
                </Button>
              }
            </>
          ) : (
            <Button
              fontFamily="Bebas Neue"
              fontWeight="400"
              fontSize="2rem"
              borderRadius=".4rem"
              background="linear-gradient(90deg,#745AC3 66.98%,rgba(248,138,176,.8) 100%)"
              color="white"
              padding="23px 25px"
              w="100%"
              className="auth-btn"
              onClick={() => navigate("/login")}
              zIndex="10"
            >
              Login to buy now
            </Button>
          )}
          {show && (
            <Button
              variant="transparent"
              display="flex"
              alignItems="center"
              gap="5px"
              p="0px"
              mt="10px"
              color="#3D3D3D"
              fontFamily="Montserrat"
              fontWeight="600"
              fontSize=".9rem"
              onClick={() => setShow(!show)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="20"
                viewBox="0 0 27 20"
                fill="none"
              >
                <path
                  d="M9.64729 1.35278L1 10L9.64729 18.6473"
                  stroke="#3D3D3D"
                  stroke-width="2.03551"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.2181 10.0002L1.24219 10.0002"
                  stroke="#3D3D3D"
                  stroke-width="2.03551"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Description
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default SellDetails;
