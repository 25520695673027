import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

const ItemCard = ({ t, i, convertToEther, handleClick }) => {
  return (
    <Card
      maxW="2xs"
      bg="#FAFAFA"
      borderRadius=".8rem"
      boxShadow=" rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      zIndex="1"
      key={i}
    >
      <CardBody p="13px">
        <Box
          bg="#C5C2F4"
          h="170px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <Image src={t?.imageUrl} w="100%" alt={t.name} borderRadius="lg" />
        </Box>
        <Stack mt="6" px="5px">
          <Heading
            color="#3D3D3D"
            fontFamily="Bebas Neue"
            fontWeight="400"
            lineHeight="5px"
            fontSize="1.5rem"
          >
            {t.name}
          </Heading>
          <Text
            color="#3D3D3D"
            fontFamily="Bebas Neue"
            fontWeight="400"
            lineHeight="normal"
            fontSize="1.1rem"
          >
            {convertToEther(t.value)} eth
          </Text>
        </Stack>
      </CardBody>
      <CardFooter px="5px" pt="5px" pb="10px">
        {t.minPurchaseMargin > 0 ? (
          <Button
            variant="transparent"
            color="#745AC3"
            fontFamily="Montserrat"
            fontWeight="800"
            onClick={() => handleClick(i)}
          >
            Buy
          </Button>
        ) : (
          <Text
            color="rgba(102, 102, 102, 0.70)"
            fontFamily="Montserrat"
            fontWeight="800"
            ms="11px"
            mb="5px"
          >
            Not for sale
          </Text>
        )}
      </CardFooter>
    </Card>
  );
};

export default ItemCard;
