import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import "../../styles/discover.css";
import Filters from "./Filters";
import Items from "./Items";

const Discover = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [isSwitchToggled, setIsSwitchToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [filter, setFilter] = useState("");

  const handleFilterChange = (value) => {
    console.log("Filter changed:", value);
    setFilter(value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSearch = (e) => {
    setLoading(true);
    const value = e.target.value;
    setSearchQuery(value);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleType = (t) => {
    setType(t);
  };

  return (
    <Box mb="100px" px="70px" zIndex="10" className="all-cnt">
      <Text
        fontFamily="Bebas Neue"
        fontSize="4.5rem"
        fontWeight="400"
        lineHeight="normal"
        color="#3B3B3B"
        zIndex="10"
        className="all-title"
      >
        DISCOVER NFTS
      </Text>
      <Box display="flex" gap="30px" mt="10px" zIndex="10" className="pc">
        {[
          "All Categories",
          "Lands",
          "Wearables",
          // "Collectibles",
          // "My Collections",
        ].map((category) => (
          <Button
            key={category}
            border="1.5px solid #745AC3"
            color={selectedCategory === category ? "white" : "#745AC3"}
            fontFamily="Montserrat"
            fontWeight="700"
            bg={selectedCategory === category ? "#745AC3" : "transparent"}
            _hover={{
              bg: selectedCategory === category ? "#745AC3" : "transparent",
            }}
            _active={{
              bg: selectedCategory === category ? "#745AC3" : "transparent",
            }}
            onClick={() => handleCategoryClick(category)}
            zIndex="10"
          >
            {category}
          </Button>
        ))}
      </Box>
      <Box className="ph">
        <Box display="flex" gap="10px" mt="10px" zIndex="10">
          {[
            "All Categories",
            "Lands",
            "Wearables",
            // "Collectibles",
            // "My Collections",
          ].map((category) => (
            <Button
              key={category}
              border="1.5px solid #745AC3"
              color={selectedCategory === category ? "white" : "#745AC3"}
              fontFamily="Montserrat"
              fontWeight="700"
              paddingX="5px"
              bg={selectedCategory === category ? "#745AC3" : "transparent"}
              _hover={{
                bg: selectedCategory === category ? "#745AC3" : "transparent",
              }}
              _active={{
                bg: selectedCategory === category ? "#745AC3" : "transparent",
              }}
              onClick={() => handleCategoryClick(category)}
              zIndex="10"
            >
              {category}
            </Button>
          ))}
        </Box>
      </Box>
      {selectedCategory === "My Collections" ? (
        <Box>
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            mt="30px"
            alignItems="center"
            gap="30px"
            zIndex="10"
            className="dis-sr-wrap"
          >
            <Filters onFilterChange={handleFilterChange} />
          </Box>

          {searchQuery.trim() === "" ? (
            <>
              {type === "" ? (
                <>
                  <Box mt="50px">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text
                        fontFamily="Bebas Neue"
                        fontSize="2rem"
                        fontWeight="400"
                        lineHeight="normal"
                        color="#3B3B3B"
                        zIndex="1"
                        mb="10px"
                      >
                        TRENDING NFTS
                      </Text>
                      <Button
                        variant="transparent"
                        p="0px"
                        textDecoration="underline"
                        color="#745AC3"
                        fontFamily="Montserrat"
                        fontWeight="500"
                        lineHeight="normal"
                        onClick={() => handleType("a")}
                      >
                        Show All
                      </Button>
                    </Box>
                    <Items
                      isSwitchToggled={isSwitchToggled}
                      category={selectedCategory}
                      searchQuery={searchQuery}
                      loading={loading}
                      type={false}
                      filter={filter}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box mt="50px">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text
                        fontFamily="Bebas Neue"
                        fontSize="2rem"
                        fontWeight="400"
                        lineHeight="normal"
                        color="#3B3B3B"
                        zIndex="10"
                        mb="10px"
                      >
                        {type === "a" && "TRENDING NFTS"}
                        {type === "b" && "NEW DROPS"}
                        {type === "c" && "VOLAVERSE EXCLUSIVE"}
                      </Text>
                      <Button
                        variant="transparent"
                        p="0px"
                        textDecoration="underline"
                        color="#745AC3"
                        fontFamily="Montserrat"
                        fontWeight="500"
                        lineHeight="normal"
                        onClick={() => handleType("")}
                      >
                        Show Less
                      </Button>
                    </Box>

                    <Items
                      isSwitchToggled={isSwitchToggled}
                      category={selectedCategory}
                      searchQuery={searchQuery}
                      loading={loading}
                      type={true}
                      filter={filter}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="50dvh"
                >
                  <Spinner
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#745AC3"
                    size="lg"
                  />
                </Box>
              ) : (
                <Box>
                  <Items
                    isSwitchToggled={isSwitchToggled}
                    category={selectedCategory}
                    searchQuery={searchQuery}
                    loading={loading}
                    type={false}
                    filter={filter}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Discover;
