import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import Layout from "../components/layout/Layout";
import ProfileItems from "../components/mainComponents/ProfileItems";
import "../styles/auth.css";
import "../styles/profile.css";
import Web3 from "web3";
import { abi } from "../components/abi/marketplace";
import { convertToEther } from "../components/modals/Details";
import {
  landAddress,
  marketplaceAddress,
  wearableAddress,
  priorityFee
} from "../utils/utils";

const Profile = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const navigate = useNavigate();
  const [tokens, setTokens] = useState([]);
  const [balance, setBalance] = useState("Loading");
  const [land, setLand] = useState([]);
  const [wearable, setWearable] = useState([]);
  const [collectible, setCollectible] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { ethereum } = window;
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);

  const { address, isConnected } = useAccount();
  console.log(address);
  console.log(isConnected);

  async function getProceedings() {
    try {
      if (!web3 || !contract || !address) {
        return;
      }

      const receipt = await contract.methods.getProceeds(address).call();
      console.log(receipt);
      if (receipt === 0n) setBalance(0);
      else setBalance(receipt);
      console.log("Transaction receipt:", receipt);
    } catch (error) {
      console.error("Error :", error);
    }
  }

  useEffect(() => {
    if (ethereum) {
      const web3Instance = new Web3(ethereum);
      setWeb3(web3Instance);
      const contractInstance = new web3Instance.eth.Contract(
        abi,
        marketplaceAddress
      );
      setContract(contractInstance);
    } else {
      console.log("Please install MetaMask!");
    }
  }, [ethereum]);

  useEffect(() => {
    if (contract && address) {
      getProceedings();
    }
  }, [contract, address]);

  useEffect(() => {
    if (!isConnected) {
      // navigate("/login");
    }
  }, [isConnected, navigate]);

  const handleWithdrawal = async () => {
    console.log("In");
    try {
      if (!web3 || !contract || !address) {
        alert("Please connect to MetaMask.");
        return;
      }
      console.log("Here");
      const receipt = await contract.methods
        .withdrawProceeds()
        .send({ from: address ,maxPriorityFeePerGas: priorityFee});
      console.log(receipt);
      if (receipt) setBalance(0); // Set to 0 or update based on actual balance after withdrawal
      console.log("Transaction receipt:", receipt);
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const getUserNfts = useCallback(
    async (category) => {
      setLoading(true);
      try {
        if (isConnected) {
          console.log(address);
          const res = await axios.get(
            `https://backend.volaverse.com/block/getUserNfts?address=${address}`
          );
          const resAllNfts = await axios.get(
            "https://backend.volaverse.com/block/nftListings"
          );
          console.log(res);

          const allNft = resAllNfts?.data?.data;
          console.log(allNft);
          const data = res?.data?.data;
          console.log(data);
          console.log(data.Land[0]);
          console.log(data.Wearable[0]);
          setTokens(data);

          const addNftAddressAndCategory = (instances, address, category) => {
            return instances.map((instance) => {
              // Check if the token is listed in the allNft data
              const isListed = allNft[category]?.some(
                (nft) => nft.tokenId === instance.id
              );
              return {
                ...instance,
                nftAddress: address,
                category: category,
                listed: isListed,
              };
            });
          };

          let mergedTokenInstancesArray = [];
          console.log(category);

          if (category === "All Categories") {
            console.log(data.Wearable.length);
            if (data.Land.length >= 1 && data.Wearable.length >= 1) {
              mergedTokenInstancesArray = [
                {
                  tokenInstances: [
                    ...addNftAddressAndCategory(
                      data.Land[0]?.tokenInstances,
                      landAddress,
                      "Land"
                    ),
                    ...addNftAddressAndCategory(
                      data.Wearable[0]?.tokenInstances,
                      wearableAddress,
                      "Wearable"
                    ),
                  ],
                },
              ];
            } else if (data.Land.length >= 1) {
              mergedTokenInstancesArray = [
                {
                  tokenInstances: addNftAddressAndCategory(
                    data.Land[0]?.tokenInstances,
                    landAddress,
                    "Land"
                  ),
                },
              ];
            } else if (data.Wearable.length >= 1) {
              mergedTokenInstancesArray = [
                {
                  tokenInstances: addNftAddressAndCategory(
                    data.Wearable[0]?.tokenInstances,
                    wearableAddress,
                    "Wearable"
                  ),
                },
              ];
            }
            console.log(mergedTokenInstancesArray);
          }

          if (category === "Lands") {
            setLand(
              data?.Land.map((land) => ({
                ...land,
                tokenInstances: addNftAddressAndCategory(
                  land.tokenInstances,
                  landAddress,
                  "Land"
                ),
              })) || []
            );
          } else if (category === "Wearables") {
            setWearable(
              data?.Wearable.map((wearable) => ({
                ...wearable,
                tokenInstances: addNftAddressAndCategory(
                  wearable.tokenInstances,
                  wearableAddress,
                  "Wearable"
                ),
              })) || []
            );
          } else if (category === "Collectibles") {
            setCollectible(data?.Collectible || []);
          } else if (category === "All Categories") {
            setAllCategories(mergedTokenInstancesArray);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [address, isConnected]
  );

  useEffect(() => {
    getUserNfts(selectedCategory);
  }, [selectedCategory, getUserNfts]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    getUserNfts(category);
  };

  return (
    <Layout title={"Volastore - Profile"} style={{ overflow: "hidden" }}>
      <Box px="70px" py="70px" className="all-cnt">
        <Text
          fontFamily="Bebas Neue"
          fontSize="4rem"
          fontWeight="400"
          lineHeight="normal"
          color="#3B3B3B"
          zIndex="10"
          mb="15px"
          className="all-title"
        >
          My account
        </Text>

        <ConnectButton />

        <Box display="flex" alignItems="center" gap="10px" mb="15px">
          <Text
            fontFamily="Bebas Neue"
            fontSize="1.8rem"
            fontWeight="400"
            lineHeight="normal"
            color="#3B3B3B"
            marginTop={2}
            marginBottom={2}
            zIndex="10"
            className="sm-title"
          >
            Proceedings Balance :
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize="1.2rem"
            fontWeight="500"
            lineHeight="normal"
            color="#3B3B3B"
            className="sm-p"
          >
            {console.log(balance)}

            {balance === "Loading"
              ? "Loading"
              : convertToEther(balance) === "0."
              ? "0 ETH"
              : convertToEther(balance) + " ETH"}
          </Text>
          <Text fontWeight="700" fontSize="1.2rem">
            ( Your Balance will be updated after selling )
          </Text>
        </Box>
        <Button
          border="1.5px solid #745AC3"
          color="white"
          fontFamily="Montserrat"
          fontWeight="500"
          bg="#745AC3"
          _hover={{
            bg: "#745AC3",
          }}
          _active={{
            bg: "#745AC3",
          }}
          zIndex="10"
          mb="50px"
          onClick={handleWithdrawal}
        >
          Withdrawal
        </Button>
        <Text
          fontFamily="Bebas Neue"
          fontSize="4rem"
          fontWeight="400"
          lineHeight="normal"
          color="#3B3B3B"
          zIndex="10"
          className="all-title"
          marginTop={"50px"}
        >
          Your collection
        </Text>
        <Box
          display="flex"
          gap="30px"
          mt="10px"
          zIndex="10"
          className="pr-btns"
        >
          {/* Collectibles in arry to make it work  */}
          {["All Categories", "Lands", "Wearables"].map((category) => (
            <Button
              key={category}
              border="1.5px solid #745AC3"
              color={selectedCategory === category ? "white" : "#745AC3"}
              fontFamily="Montserrat"
              fontWeight="700"
              bg={selectedCategory === category ? "#745AC3" : "transparent"}
              _hover={{
                bg: selectedCategory === category ? "#745AC3" : "transparent",
              }}
              _active={{
                bg: selectedCategory === category ? "#745AC3" : "transparent",
              }}
              onClick={() => handleCategoryClick(category)}
              zIndex="10"
            >
              {category}
            </Button>
          ))}
        </Box>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            h="40dvh"
          >
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#745AC3"
              size="lg"
            />
          </Box>
        ) : (
          <Box my="50px">
            {selectedCategory === "Lands" && <ProfileItems tokens={land} />}
            {selectedCategory === "Wearables" && (
              <ProfileItems tokens={wearable} />
            )}
            {selectedCategory === "Collectibles" && (
              <ProfileItems tokens={collectible} />
            )}
            {selectedCategory === "All Categories" && (
              <ProfileItems tokens={allCategories} />
            )}
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Profile;
