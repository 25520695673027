const links = [
  {
    name: "about us",
    link: "https://www.volaverse.com/#about",
  },
  {
    name: "roadmap",
    link: "https://www.volaverse.com/#roadmap",
  },
  {
    name: "team",
    link: "https://www.volaverse.com/#team",
  },
  {
    name: "faq",
    link: "https://www.volaverse.com/#faq",
  },
  {
    name: "community",
    link: "https://www.volaverse.com/#community",
  },
  {
    name: "docs",
    link: "https://www.volaverse.com/#docs",
  },
  {
    name: "associations",
    link: "https://www.volaverse.com/#association",
  },
  {
    name: "blogs",
    link: "https://www.volaverse.com/#blogs",
  },
];

export default links;
