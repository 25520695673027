import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";

const Filters = ({ onFilterChange }) => {
  const [filter, setFilter] = useState("");

  const handleFilterChange = (value) => {
    setFilter(value);
    onFilterChange(value);
  };

  const radioStyles = {
    control: {
      _focus: {
        boxShadow: "none",
      },
    },
  };

  return (
    <Popover popperProps={{ zIndex: 9999 }}>
      <PopoverTrigger>
        <Button
          bg="#745AC3"
          color="white"
          fontFamily="Montserrat"
          fontWeight="600"
          _hover={{
            bg: "#745AC3",
          }}
          _active={{
            bg: "#745AC3",
          }}
          zIndex="10"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M9.66499 2.61487L5.55451 9.20163M5.05425 2.61487H16.06C16.9771 2.61487 17.7275 3.36526 17.7275 4.2824V6.11669C17.7275 6.78371 17.3106 7.61747 16.8938 8.03436L13.3086 11.2027C12.8083 11.6196 12.4748 12.4533 12.4748 13.1203V16.7055C12.4748 17.2058 12.1413 17.8728 11.7244 18.1229L10.5571 18.8733C9.47322 19.5404 7.97244 18.79 7.97244 17.4559V13.037C7.97244 12.4533 7.63893 11.7029 7.30543 11.2861L4.13711 7.95098C3.72023 7.5341 3.38672 6.78371 3.38672 6.28345V4.36578C3.38672 3.36526 4.13711 2.61487 5.05425 2.61487Z"
                stroke="white"
                stroke-width="1.25065"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>{" "}
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold" textAlign="center">
          Filters
        </PopoverHeader>
        <PopoverBody>
          <PopoverBody>
            Value:
            <RadioGroup
              styles={radioStyles}
              display="flex"
              flexDir="column"
              value={filter}
              onChange={(fil) => handleFilterChange(fil)}
              my="10px"
            >
              <Radio className="radio" colorScheme="purple" value="a">
                Low to high
              </Radio>
              <Radio className="radio" colorScheme="purple" value="b">
                High to low
              </Radio>
            </RadioGroup>
            <Box mt="20px" textAlign="center">
              <Button
                bg="#745AC3"
                color="white"
                fontFamily="Montserrat"
                fontWeight="600"
                _hover={{
                  bg: "#745AC3",
                }}
                _active={{
                  bg: "#745AC3",
                }}
                onClick={() => window.location.reload()}
              >
                Reset
              </Button>
            </Box>
          </PopoverBody>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Filters;
