import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import { liskSepolia, sepolia,lisk } from "wagmi/chains";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Profile from "./pages/Profile";

const config = getDefaultConfig({
  appName: "RainbowKit App", //ignore this
  projectId: "YOUR_PROJECT_ID", //ignore this
  chains: [
    lisk,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? [sepolia] : []),
  ],
  ssr: true,
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile/:address/*" element={<Profile />} />
          </Routes>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
