import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  FormControl,
  Input,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../styles/auth.css";
import { ConnectButton, WalletButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { address, isConnected } = useAccount();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);
  return (
    <Box h="100dvh" position="relative">
      <Box
        position="absolute"
        zIndex="10"
        top="50%"
        transform="translate(0%, -50%)"
        left="10%"
        className="auth-wrap"
        w="80%"
        maxW="400px"
        p="30px"
        bg="rgba(255, 255, 255, 0.5)"
        borderRadius="20px"
        boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.1)"
      >
        <Box
          display="flex"
          gap="10px"
          alignItems="center"
          className=""
          justifyContent="center"
        >
          <Image
            className="ft-logo"
            h="35px"
            w="38px"
            mt="-8px"
            src="/images/logo.png"
          />
          <Text
            color="#3B3B3B"
            fontSize="2.5rem"
            letterSpacing="2px"
            fontFamily="Bebas Neue"
            fontWeight="400"
            className=""
          >
            VOLAVERSE
          </Text>
        </Box>

        <Text
          color="#3B3B3B"
          fontSize="3.5rem"
          letterSpacing="2px"
          fontFamily="Bebas Neue"
          fontWeight="400"
          mb="0"
          textAlign="center"
          className="au-txt"
        >
          WELCOME!
        </Text>
        {message === "" ? (
          <Text
            color="#3B3B3B"
            fontSize="1.1rem"
            fontFamily="Montserrat"
            fontWeight="600"
            mt="-15px"
            mb="25px"
            textAlign="center"
          >
            Connect your wallet to get started!
          </Text>
        ) : (
          <>
            {loading ? (
              <Box display="flex" justifyContent="center" mt="-5px" mb="25px">
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="transparent"
                  color="red"
                  size="sm"
                />
              </Box>
            ) : (
              <Text
                color="red"
                fontSize="1.1rem"
                fontFamily="Montserrat"
                fontWeight="600"
                mt="-15px"
                mb="25px"
                textAlign="center"
              >
                {message}
              </Text>
            )}
          </>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          className="pc"
        >
          <WalletButton wallet="metamask" />
        </Box>

        <Text
          className="ph"
          textAlign="center"
          color="#745AC3"
          fontWeight="900"
        >
          Please access our website on a desktop to log in.
        </Text>
      </Box>

      {/**
       * Left Side
       * Add Following Instructions in a box:
       * 
       *Steps to Login:
        *1. Install Metamask extension in your browser.
        2. visit lisk.portal.com (Click on the link)
        3. Connect your wallet.
        4. Select Metamask
        5. Lisk Sepolia will be connected to your wallet.
        6. Continue to login to volaverse using the login button
       *
       */}

      <Box
        position="absolute"
        zIndex="10"
        top="50%"
        transform="translate(0%, -50%)"
        right="10%"
        className="auth-wrap pc"
        w="80%"
        maxW="400px"
        p="30px"
        bg="rgba(255, 255, 255, 0.8)"
        borderRadius="8px"
      >
        <Text
          color="#3B3B3B"
          fontSize="1.8rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          Steps to Login:
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          1. Install Metamask extension in your browser.
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          2. visit{" "}
          <a
            className="portalLink"
            href="https://portal.lisk.com"
            target="_blank"
          >
            portal.lisk.com
          </a>{" "}
          (Click on the link)
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          3. Connect your wallet.
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          4. Select Metamask
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          5. Lisk Sepolia will be connected to your wallet.
        </Text>
        <Text
          color="#3B3B3B"
          fontSize="1rem"
          fontFamily="Montserrat"
          fontWeight="600"
          mt="-15px"
          mb="25px"
          textAlign="left"
        >
          6. Continue to login to volaverse using the login button
        </Text>
      </Box>

      <Box
        bg="linear-gradient(99deg, #E8EDF0 1%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(67deg, #6E6E6E 31.56%, rgba(255, 255, 255, 0.00) 65.36%)"
        h="100dvh"
        w="100dvw"
        position="absolute"
      ></Box>
      <Image h="100%" w="100%" src="/images/auth.jpg" alt="Background Image" />
    </Box>
  );
};

export default Login;
