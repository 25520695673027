import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import Loader from "./Loader";

function Model({ link, load }) {
  const groupRef = useRef();
  const { scene } = useGLTF(link);

  useEffect(() => {
    const loader = new GLTFLoader();

    loader.load(link, (gltf) => {
      const bbox = new THREE.Box3().setFromObject(gltf.scene);
      const center = bbox.getCenter(new THREE.Vector3());

      if (groupRef.current) {
        groupRef.current.position.x = -center.x;
        groupRef.current.position.y = -center.y;
        groupRef.current.position.z = -center.z;

        load(false);
      }
    });
  }, [link, load]);

  return (
    <group ref={groupRef}>
      <primitive object={scene} />
    </group>
  );
}

export default function ModelViewer({ link }) {
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [z, setZ] = useState();
  const [f, setF] = useState();

  const [isLoading, load] = useState(true);

  return (
    <>
      <Canvas
        className="model"
        pixelRatio={[1, 4]}
        camera={{
          position: [10, 10, 10],
          fov: 5,
        }}
      >
        <ambientLight intensity={1} />
        <Suspense fallback={<Handle load={load} />}>
          <Model link={link} load={load} />
        </Suspense>

        <OrbitControls />
      </Canvas>
      {isLoading && <Loader />}
    </>
  );
}

function Handle({ load }) {
  useEffect(() => {
    load(true);
    return () => load(false);
  }, []);
}
