import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import Web3 from "web3"; // Ensure this import is correct
import { abi } from "../abi/marketplace";
import SellDetails from "../modals/SellDetails";
import UpdateListing from "../modals/UpdateListing";
import ModelViewer from "./Model";
import { marketplaceAddress,priorityFee } from "../../utils/utils";
import { useLocation } from "react-router-dom";

const ProfileItems = ({ tokens = [] }) => {
  // console.log(tokens);
  const location = useLocation();
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const { address } = useAccount();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const data = tokens.length > 0 ? tokens[0]?.tokenInstances : [];
  console.log(data);

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const nftId = params.get("nftId");
    const nftAddress = params.get("nftAddress");
    console.log("nftId:", nftId);
    console.log("nftAddress:", nftAddress);
    return { nftId, nftAddress };
  };

  useEffect(() => {
    const { nftId, nftAddress } = getQueryParams();
    if (nftId && nftAddress) {
      const token = data.find(
        (token) =>
          token.id === nftId &&
          token.nftAddress.toLowerCase() === nftAddress.toLowerCase()
        );
        console.log(token)
      if (token) {
        const index = data.indexOf(token);
        console.log(index)
        setIndex(index);
        onOpen();
      }
    }
  }, [location.search, tokens, onOpen]);

  useEffect(() => {
    const initializeWeb3 = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const contractInstance = new web3Instance.eth.Contract(
            abi,
            marketplaceAddress
          );
          setContract(contractInstance);
        } catch (error) {
          console.error("Error initializing web3:", error);
        }
      } else {
        alert("Please install MetaMask!");
        console.log("Please install MetaMask!");
      }
    };

    initializeWeb3();
  }, []);

  const [index, setIndex] = useState(0);

  const handleClick = (i) => {
    setIndex(i);
    onOpen();
  };

  const handleCancel = async (i) => {
    try {
      const receipt = await contract.methods
        .cancelListing(data[i].nftAddress, data[i].id)
        .send({ from: address,maxPriorityFeePerGas: priorityFee });
      // console.log("Transaction receipt:", receipt);
    } catch (error) {
      console.error("Error canceling listing:", error);
    }
  };

  const nextToken = () => {
    setIndex((index + 1) % data.length);
  };

  const prevToken = () => {
    setIndex((index - 1 + data.length) % data.length);
  };

  return (
    <>
      {data.length === 0 ? (
        <Box
          h="40dvh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text>No tokens found!</Text>
        </Box>
      ) : (
        <Box
          zIndex="10"
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
          columnGap="10px"
          rowGap="30px"
          className="items-wrap"
        >
          {data.map((t, i) => (
            <Card
              maxW="2xs"
              bg="#FAFAFA"
              borderRadius=".8rem"
              boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
              zIndex="10"
              key={i}
            >
              <CardBody p="13px">
                <Box
                  bg="#C5C2F4"
                  h="170px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                >
                  <img
                    src={t?.image_url}
                    w="100%"
                    alt={t?.metadata?.name}
                    borderRadius="lg"
                  />
                </Box>
                <Stack mt="6" px="5px">
                  <Heading
                    color="#3D3D3D"
                    fontFamily="Bebas Neue"
                    fontWeight="400"
                    lineHeight="5px"
                    fontSize="1.5rem"
                  >
                    {t?.metadata?.name}
                  </Heading>
                </Stack>
              </CardBody>
              <CardFooter px="5px" pt="5px" pb="10px">
                {!t.listed ? (
                  <Button
                    variant="transparent"
                    color="#745AC3"
                    fontFamily="Montserrat"
                    fontWeight="800"
                    onClick={() => handleClick(i)}
                  >
                    Sell
                  </Button>
                ) : (
                  <Box display="flex" alignItems="center">
                    <UpdateListing data={data} i={i} />

                    <Button
                      variant="transparent"
                      color="#745"
                      fontFamily="Montserrat"
                      fontWeight="800"
                      onClick={() => handleCancel(i)}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </CardFooter>
            </Card>
          ))}
        </Box>
      )}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay backdropFilter="auto" backdropBlur="5px" />
        <ModalContent
          bg="#E5E9F2"
          borderRadius="1.5rem"
          className="token-cnt"
          minW="92%"
          p="20px"
        >
          <span className="modal-close-btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 83 83"
              fill="none"
            >
              <g clip-path="url(#clip0_1239_1959)">
                <path
                  d="M34.5822 48.4166L48.4154 34.5834M48.4154 48.4166L34.5822 34.5834M58.7903 58.7916C68.3007 49.2812 68.3007 33.7188 58.7903 24.2084C49.28 14.6981 33.7176 14.6981 24.2072 24.2084C14.6969 33.7188 14.6969 49.2812 24.2072 58.7916C33.7176 68.3019 49.28 68.3019 58.7903 58.7916Z"
                  stroke="#3D3D3D"
                  stroke-width="4.14991"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1239_1959">
                  <rect
                    width="58.6895"
                    height="58.6895"
                    fill="white"
                    transform="translate(0 41.5) rotate(-45)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <Button
            className="side-btns"
            variant="transparent"
            p="0"
            h="50px"
            w="50px"
            position="absolute"
            left="-50px"
            top="50%"
            transform="translate(0, -50%)"
            onClick={prevToken}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="93"
              height="96"
              viewBox="0 0 93 96"
              fill="none"
            >
              <path
                d="M56.9994 79.6799L30.9194 53.5999C27.8394 50.5199 27.8394 45.4799 30.9194 42.3999L56.9994 16.3199"
                stroke="#3D3D3D"
                stroke-width="6"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <Button
            className="side-btns"
            variant="transparent"
            p="0"
            h="50px"
            w="50px"
            position="absolute"
            right="-50px"
            top="50%"
            transform="translate(0, -50%)"
            onClick={nextToken}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="93"
              height="96"
              viewBox="0 0 93 96"
              fill="none"
            >
              <g filter="url(#filter0_b_480_1847)">
                <path
                  d="M35.6406 79.6799L61.7206 53.5999C64.8006 50.5199 64.8006 45.4799 61.7206 42.3999L35.6406 16.3199"
                  stroke="#3D3D3D"
                  stroke-width="6"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_480_1847"
                  x="-4"
                  y="-4"
                  width="104"
                  height="104"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_480_1847"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_480_1847"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Button>
          <ModalBody
            p="0"
            w="100%"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="20px"
            className="mn-cnt"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="#C5C2F4"
              borderLeftRadius="1rem"
              borderRightRadius=".7rem"
              className="md-cnt"
            >
              {console.log(data && data[index]?.metadata.model)}
              {data.length > 0 && data[index]?.metadata.model ? (
                <ModelViewer link={data && data[index]?.metadata.model} />
              ) : (
                <Box
                  h="50dvh"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Box>
              )}
            </Box>

            <Box>
              {data[index] && <SellDetails owned={false} data={data[index]} />}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileItems;
