import React from "react";
import Layout from "../components/layout/Layout";
import Home from "../components/mainComponents/Home";
import Discover from "../components/mainComponents/Discover";
import { useAccount } from "wagmi";

const Main = () => {
  const { address, isConnected } = useAccount();
  console.log(address);
  return (
    <Layout title={"Volastore"} style={{ overflow: "hidden" }}>
      <section id="home">
        <Home />
      </section>

      <section id="discover">
        <Discover />
      </section>
    </Layout>
  );
};

export default Main;
