import { Box, Button, Image, Text } from "@chakra-ui/react";
import React from "react";
// import { HashLink as Link } from "react-router-hash-link";
import "../../styles/home.css";

const Home = () => {
  const handleClick = () => {
    window.scrollTo(0, 780);
  };
  return (
    <Box
      minH="100dvh"
      px="100px"
      position="relative"
      w="100dvw"
      zIndex="10"
      className="all-cnt hm-cnt"
    >
      <Box
        display="flex"
        position="absolute"
        width="100%"
        left="0"
        top="10%"
        zIndex="10"
        className="mn-cnt"
      >
        <Box
          display="flex"
          maxH="80dvh"
          justifyContent="center"
          alignItems="center"
          zIndex="10"
          className="img-cnt"
        >
          <Image
            zIndex="10"
            src="/images/homeImg.png"
            maxH="100%"
            alt="Home Image"
          />
        </Box>
        <Box display="flex" alignItems="center" me="100px" className="hm-txt-m">
          <Box textAlign="center">
            <Text
              fontFamily="Bebas Neue"
              fontSize="4.5rem"
              fontWeight="400"
              lineHeight="normal"
              letterSpacing="8px"
              color="#3B3B3B"
              className="rs-txt"
            >
              COLLECT
            </Text>
            <Box
              bg="#745AC3"
              borderRadius=".6rem"
              px="20px"
              transform="rotate(-5deg)"
              mt="-12px"
              className="bx"
            >
              <Text
                fontFamily="Bebas Neue"
                fontSize="4.5rem"
                fontWeight="400"
                lineHeight="normal"
                letterSpacing="8px"
                color="#fff"
                className="bx-txt"
                display="flex"
                justifyContent="center"
              >
                NEW NFTS
              </Text>
            </Box>
            <Text
              fontFamily="Bebas Neue"
              fontSize="4.5rem"
              fontWeight="400"
              lineHeight="normal"
              letterSpacing="8px"
              color="#3B3B3B"
              mt="-4px"
              className="rs-txt  m-txt"
            >
              FOR YOU
            </Text>
            <Button
              fontFamily="Bebas Neue"
              fontWeight="400"
              fontSize="2.5rem"
              background="linear-gradient(90deg,#745AC3 66.98%,rgba(248,138,176,.8) 100%)"
              boxShadow="box-shadow: 7px 7px 15px 0px rgba(0, 0, 0, 0.25)"
              color="white"
              padding="25px 25px"
              className="home-btn"
              onClick={handleClick}
              zIndex="10"
              mt="20px"
            >
              EXPLORE{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="30"
                viewBox="0 0 32 44"
                fill="none"
              >
                <path
                  d="M1.99609 27.6055L15.9993 41.6088L30.0026 27.6055"
                  stroke="white"
                  stroke-width="3.29629"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 2.39143L16 41.2177"
                  stroke="white"
                  stroke-width="3.29629"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Text
          fontFamily="Bebas Neue"
          fontWeight="400"
          color=""
          lineHeight="normal"
          background="linear-gradient(180deg, #745AC3 3.57%, rgba(217, 217, 217, 0.00) 60.68%)"
          backgroundClip="text"
          className="bg-txt"
          fontSize="20rem"
          textAlign="center"
        >
          VOLASTORE
        </Text>
      </Box>
    </Box>
  );
};

export default Home;
