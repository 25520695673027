import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Web3 } from "web3";
import "../../styles/items.css";
import "../../styles/modals.css";
import { landAbi } from "../abi/land";
import { abi } from "../abi/marketplace";
import { wearableAbi } from "../abi/wearable";
import Details, { convertToEther } from "../modals/Details";
import ModelViewer from "./Model";
import {
  marketplaceAddress,
  landAddress,
  wearableAddress,
} from "../../utils/utils";
import ItemCard from "./ItemCard";
import { useLocation } from "react-router-dom";

const web3 = new Web3("https://rpc.api.lisk.com");

const contract = new web3.eth.Contract(abi, marketplaceAddress);
const land = new web3.eth.Contract(landAbi, landAddress);
const wearables = new web3.eth.Contract(wearableAbi, wearableAddress);

const { ethereum } = window;

const Items = ({
  isSwitchToggled,
  category,
  searchQuery,
  loading,
  type,
  filter,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [tokens, setTokens] = useState([]);
  const [index, setIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const [web3, setWeb3] = useState(null);
  const { address, isConnected } = useAccount();

  const getQueryParams = () => {
    const params = new URL(document.location).searchParams;
    const nftId = params.get("nftId");
    // console.log(nftId)
    const nftAddress = params.get("nftAddress");
    // console.log(nftAddress)
    return { nftId, nftAddress };
  };

  useEffect(() => {
    const { nftId, nftAddress } = getQueryParams();
    if (nftId && nftAddress) {
      const token = tokens.find(
        (token) =>
          token.id === nftId &&
          token.nftAddress.toLowerCase() === nftAddress.toLowerCase()
        );
        console.log(token)
      if (token) {
        const index = tokens.indexOf(token);
        // console.log(index)
        setIndex(index);
        onOpen();
      }
    }
  }, [location.search, tokens, onOpen]);

  const getAllTokens = async () => {
    setLoaded(false);
    try {
      const res = await fetch(
        "https://backend.volaverse.com/block/nftListings"
      );
      const data = await res.json();

      // Create a unified array with basic structure
      const unifiedArray = [];

      data.data.Land.forEach((item) => {
        unifiedArray.push({
          id: item.tokenId,
          nftAddress: landAddress,
          value: item.price,
          ownerAddress: item.owner,
          minPurchaseMargin: 1,
          category: 0, // 0 for Lands
          name: "",
          description: "",
          imageUrl: "",
          threeDUrl: "",
        });
      });

      data.data.Wearable.forEach((item) => {
        unifiedArray.push({
          id: item.tokenId,
          nftAddress: wearableAddress,
          value: item.price,
          ownerAddress: item.owner,
          minPurchaseMargin: 1,
          category: 1, // 1 for Wearable
          name: "",
          description: "",
          imageUrl: "",
          threeDUrl: "",
        });
      });
      // console.log("data: ", unifiedArray);

      // Function to fetch additional details
      async function fetchDetails(item) {
        const { id, category } = item;

        try {
          let details;
          if (category === 0) {
            details = await getLandImageApi(id);
          } else {
            details = await getWearbleImageApi(id);
          }

          const { name, description, image, model } = details.data;

          setLoaded(true);
          return {
            ...item,
            name,
            description,
            imageUrl: image,
            threeDUrl: model,
          };
        } catch (error) {
          console.error(`Error fetching details for tokenId ${id}:`, error);
          setLoaded(true);
          return item; // Return the item as is if there was an error
        }
      }

      // Fetch details for all items
      const detailedArray = await Promise.all(unifiedArray.map(fetchDetails));


      // Set the state with the final unified array
      setTokens(detailedArray);
      // console.log("dA: ", detailedArray);
    } catch (error) {
      console.log("Error getting token info: ", error);
    }
  };

  async function getLandImageApi(tokenId) {
    // console.log(tokenId);
    const imageApi = await land.methods.tokenURI(tokenId).call();
    // console.log(imageApi);

    const images = await axios.get(imageApi);
    // console.log(imageEnd);
    // console.log(images);
    // console.log(images);
    return images;
  }
  async function getWearbleImageApi(tokenId) {
    const imageApi = await wearables.methods.tokenURI(tokenId).call();

    const images = await axios.get(imageApi);

    // console.log(images);
    return images;
  }

  //reading from smart contract
  async function getAllListings() {
    try {
      const listings = await contract.methods.getAllListings().call();
      // console.log("Listings", listings);
      // console.log(await getWearbleImageApi(1));
      return listings;
    } catch (error) {
      console.error("Error getting all listings:", error);
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      setWeb3(web3);
    } else {
      console.log("Please install MetaMask!");
    }
  }, []);

  useEffect(() => {
    setLoad(true);
    getAllTokens();
    // getLatestBlock();
    getAllListings();
    setLoad(false);
  }, []);

  const handleClick = (i) => {
    setIndex(i);
    onOpen();
  };

  const nextToken = () => {
    setIndex((index + 1) % tokens.length);
  };

  const prevToken = () => {
    setIndex((index - 1 + tokens.length) % tokens.length);
  };

  // console.log(tokens);
  const filteredTokens = isSwitchToggled
    ? tokens?.filter((token) => token.minPurchaseMargin > 0)
    : tokens;

  const getCategoryValue = (categoryName) => {
    switch (categoryName) {
      case "Lands":
        return 0;
      case "Wearables":
        return 1;
      case "Collectibles":
        return 2;
      case "My Collections":
        return -1;
      default:
        return -1;
    }
  };

  const filteredTokensByCategory =
    category !== "All Categories"
      ? filteredTokens?.filter(
          (token) => token.category === getCategoryValue(category)
        )
      : filteredTokens;

  // console.log(filteredTokensByCategory);

  const getSortedTokens = () => {
    // setLoaded(false);
    if (filter === "a") {
      // setLoaded(true);
      return filteredTokensByCategory.slice().sort((a, b) => a.value - b.value);
    } else if (filter === "b") {
      // setLoaded(true);
      return filteredTokensByCategory.slice().sort((a, b) => b.value - a.value);
    } else {
      // setLoaded(true);
      return filteredTokensByCategory;
    }
  };

  const sortedTokens = getSortedTokens();

  const filtered =
    sortedTokens &&
    sortedTokens?.filter((token) =>
      token.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

  const onScrollUp = () => {
    setLoad(!load);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {loaded ? (
        <>
          {searchQuery.trim() === "" ? (
            <>
              <Box
                zIndex="10"
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                columnGap="10px"
                rowGap="30px"
                className="items-wrap"
                minH="30dvh"
              >
                {sortedTokens.length === 0 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    left="0"
                    h="30dvh"
                    w="100dvw"
                  >
                    <Spinner
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="transparent"
                      color="#745AC3"
                      size="md"
                    />
                  </Box>
                ) : (
                  <>
                    {type ? (
                      <>
                        {load ? (
                          <>
                            {sortedTokens &&
                              sortedTokens?.map((t, i) => (
                                <ItemCard
                                  key={i}
                                  t={t}
                                  i={i}
                                  convertToEther={convertToEther}
                                  handleClick={handleClick}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            {sortedTokens &&
                              sortedTokens
                                ?.slice(0, 10)
                                .map((t, i) => (
                                  <ItemCard
                                    key={i}
                                    t={t}
                                    i={i}
                                    convertToEther={convertToEther}
                                    handleClick={handleClick}
                                  />
                                ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {sortedTokens &&
                          sortedTokens
                            ?.slice(0, 5)
                            .map((t, i) => (
                              <ItemCard
                                key={i}
                                t={t}
                                i={i}
                                convertToEther={convertToEther}
                                handleClick={handleClick}
                              />
                            ))}
                      </>
                    )}
                  </>
                )}
              </Box>
              {type && (
                <>
                  {!load ? (
                    <Box mt="50px" textAlign="center">
                      <Button
                        bg="#745AC3"
                        color="white"
                        fontFamily="Montserrat"
                        fontWeight="600"
                        _hover={{
                          bg: "#745AC3",
                        }}
                        _active={{
                          bg: "#745AC3",
                        }}
                        zIndex="10"
                        onClick={() => setLoad(!load)}
                      >
                        Load more NFTs
                      </Button>
                    </Box>
                  ) : (
                    <Box mt="50px" display="flex" justifyContent="center">
                      <Button
                        bg="#745AC3"
                        color="white"
                        fontFamily="Montserrat"
                        fontWeight="600"
                        _hover={{
                          bg: "#745AC3",
                        }}
                        _active={{
                          bg: "#745AC3",
                        }}
                        zIndex="10"
                        onClick={onScrollUp}
                        display="flex"
                        gap="5px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="17"
                          viewBox="0 0 21 28"
                          fill="none"
                        >
                          <path
                            d="M19.2949 10.6473L10.6477 2L2.00038 10.6473"
                            stroke="white"
                            stroke-width="2.03551"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.6484 26.2173L10.6484 2.24146"
                            stroke="white"
                            stroke-width="2.03551"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Scroll Up
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Spinner
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="transparent"
                    color="#745AC3"
                    size="lg"
                  />
                </Box>
              ) : (
                <>
                  {filtered && filtered?.length > 0 ? (
                    <Box
                      zIndex="10"
                      display="grid"
                      gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                      columnGap="10px"
                      rowGap="30px"
                      className="items-wrap"
                      mt="50px"
                    >
                      {filtered &&
                        filtered?.map((t, i) => (
                          <ItemCard
                            key={i}
                            t={t}
                            i={i}
                            convertToEther={convertToEther}
                            handleClick={handleClick}
                          />
                        ))}
                    </Box>
                  ) : (
                    <Text textAlign="center" mt="70px">
                      No matching tokens found!
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          h="30dvh"
        >
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="transparent"
            color="#745AC3"
            size="md"
          />
        </Box>
      )}

      {/* modal */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay backdropFilter="auto" backdropBlur="5px" />
        <ModalContent
          bg="#E5E9F2"
          borderRadius="1.5rem"
          className="token-cnt"
          minW="92%"
          p="20px"
        >
          <span className="modal-close-btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 83 83"
              fill="none"
            >
              <g clip-path="url(#clip0_1239_1959)">
                <path
                  d="M34.5822 48.4166L48.4154 34.5834M48.4154 48.4166L34.5822 34.5834M58.7903 58.7916C68.3007 49.2812 68.3007 33.7188 58.7903 24.2084C49.28 14.6981 33.7176 14.6981 24.2072 24.2084C14.6969 33.7188 14.6969 49.2812 24.2072 58.7916C33.7176 68.3019 49.28 68.3019 58.7903 58.7916Z"
                  stroke="#3D3D3D"
                  stroke-width="4.14991"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1239_1959">
                  <rect
                    width="58.6895"
                    height="58.6895"
                    fill="white"
                    transform="translate(0 41.5) rotate(-45)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <Button
            variant="transparent"
            p="0"
            h="50px"
            w="50px"
            position="absolute"
            left="-50px"
            top="50%"
            transform="translate(0, -50%)"
            onClick={prevToken}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="93"
              height="96"
              viewBox="0 0 93 96"
              fill="none"
            >
              <path
                d="M56.9994 79.6799L30.9194 53.5999C27.8394 50.5199 27.8394 45.4799 30.9194 42.3999L56.9994 16.3199"
                stroke="#3D3D3D"
                stroke-width="6"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <Button
            variant="transparent"
            p="0"
            h="50px"
            w="50px"
            position="absolute"
            right="-50px"
            top="50%"
            transform="translate(0, -50%)"
            onClick={nextToken}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="93"
              height="96"
              viewBox="0 0 93 96"
              fill="none"
            >
              <g filter="url(#filter0_b_480_1847)">
                <path
                  d="M35.6406 79.6799L61.7206 53.5999C64.8006 50.5199 64.8006 45.4799 61.7206 42.3999L35.6406 16.3199"
                  stroke="#3D3D3D"
                  stroke-width="6"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_480_1847"
                  x="-4"
                  y="-4"
                  width="104"
                  height="104"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_480_1847"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_480_1847"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Button>
          {searchQuery && searchQuery.trim() === "" ? (
            <ModalBody
              p="0"
              w="100%"
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="20px"
              className="mn-cnt"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="#C5C2F4"
                borderLeftRadius="1rem"
                borderRightRadius=".7rem"
                className="md-cnt"
              >
                {sortedTokens && sortedTokens[index] ? (
                  <ModelViewer link={filtered[index]?.threeDUrl} />
                ) : (
                  <Box
                    h="50dvh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner />
                  </Box>
                )}
              </Box>

              <Box>
                {sortedTokens && sortedTokens[index] && (
                  <Details data={sortedTokens[index]} />
                )}
              </Box>
            </ModalBody>
          ) : (
            <ModalBody
              p="0"
              w="100%"
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="20px"
              className="mn-cnt"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="#C5C2F4"
                borderLeftRadius="1rem"
                borderRightRadius=".7rem"
                className="md-cnt"
              >
                {filtered && filtered[index] ? (
                  <ModelViewer link={filtered[index]?.threeDUrl} />
                ) : (
                  <Box
                    h="50dvh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner />
                  </Box>
                )}
              </Box>

              <Box>
                {filtered && filtered[index] && (
                  <Details owned={false} data={filtered[index]} />
                )}
              </Box>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Items;